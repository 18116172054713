'use client'

import 'core-js/features/array/flat'
import 'core-js/features/object/entries'
import 'core-js/features/promise/finally'
import 'intersection-observer'

import { ReactNode, Suspense } from 'react'
// import { analytics } from 'src/analytics'
// import { ObeSplitFactory } from 'src/components/ObeSplitFactory'
import { NavigationTrackerProvider } from 'src/providers/NavigationTrackerProvider'
import { ContextProvider } from 'src/components/App/Context'

const RootProviders = ({ children }: { children: ReactNode }) => {
  // const [isAnonymousIdReady, setIsAnonymousIdReady] = useState(false)

  // useEffect(() => {
  //   analytics.ready().then(() => setIsAnonymousIdReady(true))
  // }, [setIsAnonymousIdReady])

  return (
    <Suspense>
      {/* <ObeSplitFactory isAnonymousIdReady={isAnonymousIdReady}> */}
      <ContextProvider>
        <NavigationTrackerProvider>{children}</NavigationTrackerProvider>
      </ContextProvider>
      {/* </ObeSplitFactory> */}

      {/* set a logged in flag in the global scope for 3rd parties (OptinMonster) */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
          try {
            window.obelsLoggedIn = (!!JSON.parse(window.localStorage.getItem('__obe_'))).toString()
          } catch(error) {
            window.obelsLoggedIn = 'false'
          }
        `,
        }}
      />
    </Suspense>
  )
}

export default RootProviders
